import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { UserContext } from "../../context/UserContext";
import { formatMonto, getTotalCompras } from "../../utils";
import { ModalContext } from "../../context/ModalContext";
import AddClassesForm from "../purchases/AddClassesForm";
import RemoveClassesForm from "../clases/RemoveClassesForm";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getFriendlyLocalDateString } from "../../utils/dates";

const CustomerActions = ({ customer, showTotalPurchases }) => {
  const [copied, setCopied] = useState(false);
  const { recoverPassword } = useContext(UserContext);

  const {
    link,
    addCustomerClasses,
    getPasswordResetLink,
    removeCustomerClasses,
    clearLink,
  } = useContext(CustomerContext);

  const { success, modalComponent } = useContext(ModalContext);

  const { single_class_enabled, videos_enabled } = useContext(AppConfigContext);

  const { customer_id } = customer;

  useEffect(() => {
    return () => clearLink();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (copied) {
      success("Enlace copiado al portapapeles.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copied]);

  const confirmRemoveClasses = () => {
    modalComponent(
      "Restar Clases",
      <RemoveClassesForm
        customer_id={customer_id}
        removeClasses={removeCustomerClasses}
      />
    );
  };

  const confirmAddClasses = () => {
    modalComponent(
      "Agregar Clases",
      <AddClassesForm
        customer_id={customer_id}
        addClasses={addCustomerClasses}
      />
    );
  };

  const renderCustomerClasses = () => {
    if(customer.has_unlimited) {
      return <span className="badge badge-pill bg-primary">Ilimitadas</span>
    }
    if (Array.isArray(customer.available_classes)) {
      return customer.available_classes.length;
    }
  };

  const renderExpiration = () => {
    if (customer.has_online && customer.user === null) {
      return (
        <span>
          {" "}
          hasta {getFriendlyLocalDateString(customer.online_expiration)}
        </span>
      );
    }
  };

  const renderOnlineAccess = () => {
    if (parseInt(videos_enabled)) {
      return (
        <div className="row mb-3">
          <div className="col-6">Acceso a Clases Online</div>
          <div className="col-6">
            {customer.has_online ? (
              <span className="badge badge-pill bg-success">
                Activo{renderExpiration()}
              </span>
            ) : (
              <span className="badge badge-pill bg-secondary">Inactivo</span>
            )}
          </div>
        </div>
      );
    }
  };

  const renderLink = () => {
    if (link && link !== null) {
      return (
        <div className="row my-3 mx-0">
          <div className="col-10 px-0">
            <input disabled type="text" value={link} className="form-control" />
          </div>
          <div className="col-2 px-0">
            <button
              onClick={() => {
                window.navigator.clipboard
                  .writeText(link)
                  .then(() => setCopied(true));
              }}
              className="btn w-100 border"
            >
              <i className="fa fa-copy" />
            </button>
          </div>
        </div>
      );
    }
  };

  const renderComprasTotales = () => {
    if (showTotalPurchases) {
      return (
        <h4 className="mt-4">
          Compras Totales: {"$"}
          {formatMonto(getTotalCompras(customer))} MXN
        </h4>
      );
    }
  };

  const renderCustomerTags = () => {
    if (Array.isArray(customer?.tags)) {
      return customer.tags.map((tag) => (
        <span key={tag.tag_id} className="badge badge-pill bg-secondary me-1">
          {tag.name}
        </span>
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <h4>Reestablecer Contraseña</h4>

      <button
        className="btn btn-outline-dark me-2 my-1"
        onClick={() => recoverPassword(customer.email)}
      >
        <i className="fa fa-envelope me-2"></i> Enviar Correo
      </button>
      <button
        className="btn btn-outline-dark me-2 my-1"
        onClick={() => getPasswordResetLink(customer.email)}
      >
        <i className="fa fa-link me-2"></i> Generar Link
      </button>

      <div className="container-fluid px-0">{renderLink()}</div>

      {renderComprasTotales()}

      {single_class_enabled && (
        <div className="row mb-3">
          <div className="col-6 col-md-3 my-2">
            <span className="bold">Créditos:</span>{" "}
          </div>
          <div className="col-6 col-md-3 my-2">{renderCustomerClasses()}</div>
          <div className="col-12 col-md-6 my-2">
            <button
              className="btn btn-outline-success btn-sm me-3"
              onClick={confirmAddClasses}
            >
              <i className="fa fa-plus"></i> Clases
            </button>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={confirmRemoveClasses}
            >
              <i className="fa fa-minus"></i> Clases
            </button>
          </div>
        </div>
      )}
      {renderOnlineAccess()}

      <div className="col-6 bold">Etiquetas</div>
      {renderCustomerTags()}
    </div>
  );
};

export default CustomerActions;
